import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from './useApi';
import { RootState } from './redux/reducers';
import * as actions from './redux/actions';
import Loader from './components/Loader';
import { IUser } from './types';
import LoginApp from './apps/LoginApp';
import PhotoBase from './apps/PhotoBase';
import './App.css';
import Admin from './apps/Admin';
import { globalSelector } from './redux/selectors';

function App() {
  const api = useApi();
  const { user, loaded } = useSelector((state: RootState) => state.get('user').toJS());
  const { loading } = useSelector(globalSelector);
  const dispatch = useDispatch();

  const userLoaded = () => {
    dispatch(actions.global.setLoading(false));
    dispatch(actions.user.setLoaded(true));
  };

  const setUser = (u: IUser | null) => {
    u !== null && dispatch(actions.user.setUser(u));
  };

  const getUser = useCallback(() => {
    api
      .getUser()
      .then(u => {
        setUser(u);
        userLoaded();
      })
      .catch(() => {
        userLoaded();
      });
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {loaded && <>{!!user ? <PhotoBase /> : <LoginApp />}</>}
    </>
  );
}

export default App;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Grid } from '@material-ui/core';
import { RootState } from '../../../../redux/reducers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    bar: {
      backgroundColor: theme.palette.grey[100],
      boxShadow: '0px 2px 2px -1px rgb(0 0 0 / 10%), 0px 2px 5px 0px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 5%)',
    },
  })
);

enum Roles {
  admin = 'admin',
  director = 'директор',
  administrator = 'администратор',
  doctor = 'врач',
}

export default function Header() {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.getIn(['user'])).toJS();

  return (
    <div className={classes.root}>
      <AppBar position="relative" color="default" className={classes.bar}>
        <Toolbar>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h6" className={classes.title}>
                Nudebar Фотобаза
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.title}>
                {user.name}{' '}
                <Typography display="inline" variant="subtitle1">
                  (
                  {
                    // @ts-ignore
                    Roles[user.role]
                  }
                  )
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

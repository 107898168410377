import { Record } from 'immutable';

export interface IAddClientState {
  visible: boolean;
  loading: boolean;
  name: string;
  error: string;
  date: any;
}

export interface IState extends Record<IAddClientState> {}

export enum ActionType {
  SetLoading = 'AddClient:SetLoading',
  SetVisible = 'AddClient:SetVisible',
  SetName = 'AddClient:SetName',
  SetDate = 'AddClient:SetDate',
  SetError = 'AddClient:SetError',
}

export interface ISetLoading {
  type: ActionType.SetLoading;
  payload: {
    loading: boolean;
  };
}

export interface ISetVisible {
  type: ActionType.SetVisible;
  payload: {
    visible: boolean;
  };
}

export interface ISetName {
  type: ActionType.SetName;
  payload: {
    name: string;
  };
}

export interface ISetError {
  type: ActionType.SetError;
  payload: {
    error: string;
  };
}

export interface ISetDate {
  type: ActionType.SetDate;
  payload: {
    date: string | null;
  };
}

export type Action = ISetLoading | ISetVisible | ISetName | ISetDate | ISetError;

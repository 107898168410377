import {Record} from 'immutable';
import {Action, ActionType, IState} from './types';

const defaultState: IState = Record({
  loading: true,
})();

export default function reducer(state = defaultState, action: Action | any) {
  const {type, payload} = action;

  switch (type) {
    case ActionType.SetLoading: {
      return state.set('loading', payload.loading);
    }

    default: {
      return state;
    }
  }
}
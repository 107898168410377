import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './redux/store';
import {ThemeProvider} from '@material-ui/core';
import App from './App';
import Api from './api';
import {createApi} from './useApi';
import theme from './theme';
import reportWebVitals from './reportWebVitals';

const api = new Api();
createApi(api);

//@ts-ignore
window.api = api;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App/>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

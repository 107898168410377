import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Clients from './components/Clients';
import Root from './components/Root';
import Client from './components/Client';
import Session from './components/Session';
import Comparison from './components/Comparison';

type IProp = {};

export default function PhotoBase({}: IProp) {
  useEffect(() => {
    document.title = 'Nudebar. Фотобаза';
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/photos/comparison">
          <Comparison />
        </Route>
        <Route path="/photos/:rootID/:clientID/:sessionID">
          <Session />
        </Route>
        <Route path="/photos/:rootID/:clientID">
          <Client />
        </Route>
        <Route path="/photos/:rootID">
          <Clients />
        </Route>
        <Route path="/photos/">
          <Root />
        </Route>
        <Route path="/">
          <Redirect to="/photos" />
        </Route>
      </Switch>
    </Router>
  );
}

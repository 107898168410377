import { IBreadCrumb } from './apps/PhotoBase/types';
import { getClientName } from './utils/name';

export const makeBreadcrumbs = (params?: any): IBreadCrumb[] => {
  const res = [{ label: 'Фотобаза', href: '/photos/' }];
  if (params.root) res.push({ label: params.root.label, href: `/photos/${params.root.id}` });
  if (params.client)
    res.push({ label: getClientName(params.client), href: `/photos/${params.root.id}/${params.client.id}` });
  if (params.session)
    res.push({
      label: params.session.date,
      href: `/photos/${params.root.id}/${params.client.id}/${params.session.id}`,
    });

  return res;
};

export const formatName = (name: string): string => {
  return name.replace(/(\s|^)[а-я]/gi, a => a.toUpperCase());
};

export const getSessionStore = (): any => {
  return JSON.parse(<string>sessionStorage.getItem('Store'));
};

import React from 'react';
import TextField from '@material-ui/core/TextField';
import MUIAutocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getClientName } from '../../../../../utils/name';

type IProp = {
  onChange: (value: string) => void;
  loading: boolean;
  options: any[];
  onSelect: (value: any) => void;
};

export default function Autocomplete({ onChange, loading, options, onSelect }: IProp) {
  return (
    <MUIAutocomplete
      freeSolo
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => {
        if (!option.name) return option;
        return getClientName(option);
      }}
      options={options}
      loading={loading}
      filterOptions={x => x}
      loadingText="Загрузка..."
      noOptionsText="Ничего не найдено"
      onChange={(event, newValue, reason) => {
        if (reason !== 'select-option') return;
        onSelect(newValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Поиск"
          variant="outlined"
          onChange={event => {
            onChange(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

import React from 'react';
import {Container} from "@material-ui/core";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import LoginForm from "./components/LoginForm";

export default function LoginApp() {

  return (
    <Container>
      <Router>
        <Switch>
          <Route path="*">
            <LoginForm />
          </Route>
        </Switch>
      </Router>
    </Container>
  )
}
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

type IProp = {
  title: string;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
};

export default function ConfirmModal({ title, loading, onClose, onConfirm }: IProp) {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Отмена
        </Button>
        <Button color="primary" disabled={loading} onClick={onConfirm}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import {IApi} from "./api";

let api: any;

export function createApi(newApi: IApi) {
  api = newApi;
}

export function useApi(): IApi {
  return api;
}
import React, { useState } from 'react';
import Autocomplete from './Autocomplete';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useApi } from '../../../../useApi';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      padding: theme.spacing(3, 1.2, 0, 1.2),
    },
  })
);

export default function Search() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const api = useApi();
  const history = useHistory();

  const handleChange = async (value: string) => {
    if (!value || value.length < 3) {
      setOptions([]);
      return;
    }
    setLoading(true);
    const docs = await api.search(value);
    setOptions(docs);
    setLoading(false);
  };

  const handleSelect = (client: any) => {
    try {
      history.push(`/photos/${client.rootID}/${client.id}`);
    } catch (e) {}
  };

  return (
    <div className={classes.box}>
      <Autocomplete
        onSelect={handleSelect}
        options={options}
        loading={loading}
        onChange={_.debounce(value => handleChange(value), 500)}
      />
    </div>
  );
}

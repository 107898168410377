import React, {FormEvent, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {green} from '@material-ui/core/colors';
import * as actions from '../../../../redux/actions';
import {useApi} from '../../../../useApi';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingBottom: 0,
    textAlign: 'center',
  },
  caption: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  buttonWrapper: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    position: 'relative',
  },
  avatar: {
    margin: `${theme.spacing(2)}px auto`,
    backgroundColor: theme.palette.secondary.main,
    width: 55,
    height: 55,
  },
  textFiled: {
    marginTop: theme.spacing(2),
  },
  container: {
    margin: `${theme.spacing(4)}px auto`,
    textAlign: 'center',
    maxWidth: 450,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  }
}));

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const classes = useStyles();
  const api = useApi();
  const dispatch = useDispatch();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!email) return setError('Почта не указана');
    if (!password) return setError('Пароль не указан');

    setLoading(true);
    setError('');

    api.signIn(email, password)
      .then((u) => {
        setLoading(false);
        u ? dispatch(actions.user.setUser(u)) : setError('Пользователь не найден');
      })
      .catch((e) => {
        setLoading(false);
        setError('Неверный логин или пароль');
      });
  };

  useEffect(() => {
    document.title = 'Авторизация';
  }, []);

  return (
    <div className={classes.container}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon/>
      </Avatar>
      <Typography component="p" variant="h5">
        Войдите, чтобы продолжить
      </Typography>
      <form onSubmit={onSubmit}>
        <TextField
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          variant="outlined"
          autoFocus
          autoComplete="off"
          margin="dense"
          id="name"
          label="Введите почту"
          type="email"
          fullWidth
          className={classes.textFiled}
        />
        <TextField
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          variant="outlined"
          autoComplete="off"
          margin="dense"
          id="password"
          label="Введите пароль"
          type="password"
          fullWidth
          className={classes.textFiled}
        />
        {!!error && (
          <Alert className={classes.errorMessage} severity="error">{error}</Alert>
        )}
        <div className={classes.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            disabled={false}
          >
            Войти
          </Button>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
      </form>
    </div>
  );
}

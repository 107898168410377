import { ActionType, ISetRoot, ISetClient, ISetSession } from '../../reducers/path/types';
import { IClient, IRoot, ISession } from '../../reducers/route/types';

export function setRoot(root: IRoot | null): ISetRoot {
  return {
    type: ActionType.SetRoot,
    payload: {
      root,
    },
  };
}

export function setClient(client: IClient | null): ISetClient {
  return {
    type: ActionType.SetClient,
    payload: {
      client,
    },
  };
}

export function setSession(session: ISession | null): ISetSession {
  return {
    type: ActionType.SetSession,
    payload: {
      session,
    },
  };
}

import { ActionType, IAddToList, IRemoveFromList, ISetList } from '../../reducers/compare/types';

export function addToList(photo: string): IAddToList {
  return {
    type: ActionType.AddToList,
    payload: {
      photo,
    },
  };
}

export function removeFromList(photo: string): IRemoveFromList {
  return {
    type: ActionType.RemoveFromList,
    payload: {
      photo,
    },
  };
}

export function setList(list: string[]): ISetList {
  return {
    type: ActionType.SetList,
    payload: {
      list,
    },
  };
}

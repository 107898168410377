import { Record } from 'immutable';
import { Action, ActionType, IState } from './types';
import moment from 'moment';

const defaultState: IState = Record({
  loading: false,
  visible: false,
  name: '',
  error: '',
  date: moment(),
})();

export default function reducer(state = defaultState, action: Action | any) {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SetLoading: {
      return state.set('loading', payload.loading);
    }

    case ActionType.SetDate: {
      return state.set('date', payload.date);
    }

    case ActionType.SetName: {
      return state.set('name', payload.name);
    }

    case ActionType.SetVisible: {
      return state.set('visible', payload.visible);
    }

    case ActionType.SetError: {
      return state.set('error', payload.error);
    }

    default: {
      return state;
    }
  }
}

import React from 'react';
import { KeyboardDatePicker as DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/ru';
import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

moment.locale('ru');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    block: {
      paddingTop: theme.spacing(2),
    },
    errorMessage: {
      marginBottom: theme.spacing(2),
    },
    actions: {
      paddingTop: theme.spacing(3),
    },
  })
);

type IProp = {
  onClose: () => void;
  open?: boolean;
  title: string;
  subtitle: string;
  onDateChange: (date: string | null) => void;
  onNameChange: (name: string) => void;
  onSubmit: () => void;
  name: string;
  date: string;
  disableName: boolean;
  loading?: boolean;
  error?: string;
};

export default function AddModal({
  open,
  onClose,
  title,
  onSubmit,
  subtitle,
  onNameChange,
  name,
  onDateChange,
  date,
  disableName,
  error,
  loading,
}: IProp) {
  const classes = useStyles();

  return (
    <Dialog open={open || false} onClose={onClose} maxWidth="sm" fullWidth>
      <form
        action="#"
        onSubmit={event => {
          event.preventDefault();
          onSubmit();
        }}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {!!error && (
            <Alert severity="error" className={classes.errorMessage}>
              {error}
            </Alert>
          )}
          <DialogContentText>{subtitle}</DialogContentText>
          {!disableName && (
            <div className={classes.block}>
              <TextField
                disabled={disableName}
                onChange={event => onNameChange(event.target.value)}
                inputProps={{
                  autoComplete: 'off',
                }}
                value={name}
                autoFocus
                id="client_name"
                label="Фамилия Имя Отчество"
                type="text"
                fullWidth
                variant="outlined"
              />
            </div>
          )}
          {disableName && (
            <div className={classes.block}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
                <DatePicker
                  disableFuture
                  onChange={(date: any) => {
                    onDateChange(date);
                  }}
                  label="Дата визита"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  format="DD/MM/YYYY"
                  value={date}
                  invalidDateMessage="Неверный формат даты"
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onClose} color="primary" disabled={loading}>
            Отмена
          </Button>
          <Button color="primary" type="submit" disabled={loading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useApi } from '../../../../useApi';
import { makeBreadcrumbs } from '../../../../utils';
import * as actions from '../../../../redux/actions';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { routeParams } from '../../types';
import Main from '../Main';
import AddSessionModal from '../AddSessionModal';
import List, { Item } from '../List';
import Button from '@material-ui/core/Button';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IClient, IRoot, ISession } from '../../../../redux/reducers/route/types';
import { globalSelector, pathSelector, userSelector } from '../../../../redux/selectors';
import { hasEditPermissions } from '../../../../roles';
import ConfirmModal from '../../../../components/ConfirmModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyBox: {
      paddingTop: theme.spacing(5),
    },
  })
);

export default function Client() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const params: routeParams = useParams();
  const api = useApi();
  const [sessions, setSessions] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDeleteClientVisible, setDeleteClientVisible] = useState(false);
  const { root, client } = useSelector(pathSelector);
  const { loading } = useSelector(globalSelector);
  const { user } = useSelector(userSelector);
  const { rootID, clientID } = params;

  const setLoading = (loading: boolean) => {
    dispatch(actions.global.setLoading(loading));
  };

  const onSessionClick = (session: ISession) => {
    history.push(`/photos/${rootID}/${clientID}/${session.id}`);
    saveSession(session);
  };

  const saveClient = (client: IClient) => {
    dispatch(actions.path.setClient(client));
  };

  const saveRoot = (root: IRoot | null) => {
    dispatch(actions.path.setRoot(root));
  };

  const saveSession = (session: ISession) => {
    dispatch(actions.path.setSession(session));
  };

  const onAddSession = () => {
    setModalVisible(true);
  };

  const onClientDelete = async () => {
    setLoading(true);
    await api.deleteClient(rootID, clientID);
    const root = await api.getRoot(rootID);
    saveRoot(root);
    setDeleteClientVisible(false);
    history.push(`/photos/${rootID}`);
  };

  const hasPermission = hasEditPermissions(user?.role);

  useEffect(() => {
    (async () => {
      setLoading(true);
      dispatch(actions.path.setSession(null));

      if (!root) {
        const root = await api.getRoot(rootID);
        saveRoot(root);
      }

      if (!client) {
        const client = await api.getClient(rootID, clientID);
        saveClient(client);
      }

      const sessions = await api.getSessions(rootID, clientID || '');
      setSessions(sessions);

      setLoading(false);
    })();
  }, []);

  return (
    <Main onAddSession={onAddSession} onDeleteClient={() => setDeleteClientVisible(true)}>
      {client && root && <Breadcrumbs breadcrumbs={makeBreadcrumbs({ root, client })} />}
      {hasPermission && sessions.length === 0 && !loading ? (
        <div className={classes.emptyBox}>
          <Button
            startIcon={<EventAvailableIcon />}
            size="large"
            variant="outlined"
            color="primary"
            onClick={() => setModalVisible(true)}
          >
            Добавить визит
          </Button>
        </div>
      ) : (
        <List>
          {sessions.map(session => (
            <Item type="calendar" key={session.id} label={session.date} onClick={() => onSessionClick(session)} />
          ))}
        </List>
      )}
      <AddSessionModal open={modalVisible} onClose={() => setModalVisible(false)} />
      {isDeleteClientVisible && (
        <ConfirmModal
          title="Вы действительно хотите удалить клиента?"
          loading={loading}
          onConfirm={() => onClientDelete()}
          onClose={() => setDeleteClientVisible(false)}
        />
      )}
    </Main>
  );
}

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import * as actions from '../../../../redux/actions';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../../../useApi';
import { IRoot } from '../../../../redux/reducers/route/types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Alert } from '@material-ui/lab';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { isForeignMode } from '../../../../utils/name';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    block: {
      paddingTop: theme.spacing(2),
    },
    errorMessage: {
      marginBottom: theme.spacing(2),
    },
    actions: {
      paddingTop: theme.spacing(3),
    },
  })
);

type IProp = {
  onClose: () => void;
  open: boolean;
  title?: string;
  subtitle?: string;
  disableName?: boolean;
  root: IRoot;
};

export default function AddClientModal({ open, onClose, root }: IProp) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state: RootState) => state.getIn(['global'])).toJS();
  const classes = useStyles();
  const api = useApi();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [prevSurname, setPrevSurname] = useState('');
  const [patronymic, setPatronymic] = useState('');
  const [error, setError] = useState('');

  const setLoading = (loading: boolean) => {
    dispatch(actions.global.setLoading(loading));
  };

  const handleSubmit = async () => {
    if (!name || !surname || !root) {
      setError('Некорректное Имя');
      return;
    }

    setLoading(true);

    const id = await api.addClient(root.id, name, surname, patronymic, prevSurname);
    dispatch(actions.path.setClient({ id, name, surname, patronymic, prevSurname }));
    history.push(`/photos/${root.id}/${id}`);
  };

  const foreignMode = isForeignMode(root);
  const surnamePattern = foreignMode ? '*' : `^${root?.label?.toUpperCase()}+[а-яё|\-|\s]{1,20}`;
  const namePattern = foreignMode ? '*' : '[А-ЯЁ]+[а-яё]{1,20}';

  return (
    <Dialog open={open || false} onClose={onClose} maxWidth="sm" fullWidth>
      <form
        action="#"
        onSubmit={event => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <DialogTitle id="form-dialog-title">Добавление нового клиента</DialogTitle>
        <DialogContent>
          {!!error && (
            <Alert severity="error" className={classes.errorMessage}>
              {error}
            </Alert>
          )}
          <DialogContentText>Введите ФИО нового клиента</DialogContentText>
          <div className={classes.block}>
            <TextField
              required
              onChange={event => setSurname(event.target.value)}
              inputProps={{
                autoComplete: 'off',
                pattern: surnamePattern,
              }}
              value={surname}
              autoFocus
              label="Фамилия"
              type="text"
              fullWidth
              variant="outlined"
            />
          </div>
          <div className={classes.block}>
            <TextField
              required
              onChange={event => setName(event.target.value)}
              inputProps={{
                autoComplete: 'off',
                pattern: namePattern,
              }}
              value={name}
              label="Имя"
              type="text"
              fullWidth
              variant="outlined"
            />
          </div>
          <div className={classes.block}>
            <TextField
              onChange={event => setPatronymic(event.target.value)}
              inputProps={{
                autoComplete: 'off',
              }}
              value={patronymic}
              label="Отчество"
              type="text"
              fullWidth
              variant="outlined"
            />
          </div>
          <div className={classes.block}>
            <TextField
              onChange={event => setPrevSurname(event.target.value)}
              inputProps={{
                autoComplete: 'off',
              }}
              value={prevSurname}
              label="Девичья Фамилия"
              type="text"
              fullWidth
              variant="outlined"
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onClose} color="primary" disabled={loading}>
            Отмена
          </Button>
          <Button color="primary" type="submit" disabled={loading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

import { Record } from 'immutable';
import { Action, ActionType, IState } from './types';

const defaultState: IState = Record({
  root: undefined,
  client: undefined,
  session: undefined,
})();

export default function reducer(state = defaultState, action: Action | any) {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SetRoot: {
      return state.set('root', payload.root);
    }

    case ActionType.SetClient: {
      return state.set('client', payload.client);
    }

    case ActionType.SetSession: {
      return state.set('session', payload.session);
    }

    default: {
      return state;
    }
  }
}

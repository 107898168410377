import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {LinearProgress} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: theme.zIndex.modal + 10
    }
  }),
);

export default function Loader() {
  const classes = useStyles();

  return <LinearProgress className={classes.progress} variant="indeterminate" />
}







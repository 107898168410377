import { IClient, IRoot } from '../redux/reducers/route/types';

export const isForeignMode = (root: IRoot): boolean => {
  return root.label === 'A-Z';
};

export const getClientName = ({ name, prevSurname, surname, patronymic }: IClient): string => {
  let out = surname;
  if (prevSurname) out += ` (${prevSurname})`;
  out += ` ${name}`;
  if (patronymic) out += ` ${patronymic}`;

  return out;
};

import { combineReducers } from 'redux-immutable';
import { Record } from 'immutable';
import global from './global';
import user from './user';
import route from './route';
import addClient from './addClient';
import compare from './compare';
import path from './path';

const rootReducer = combineReducers({
  global,
  user,
  route,
  addClient,
  compare,
  path,
});

export type RootState = Record<ReturnType<typeof rootReducer>>;

export default rootReducer;

import React, { useEffect } from 'react';
import Main from '../Main';
import { useSelector, useDispatch } from 'react-redux';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as actions from '../../../../redux/actions';
import clx from 'classnames';

// @ts-ignore
import { zoom } from 'zoom-by-ironex';
import { comparisonListSelector, globalSelector } from '../../../../redux/selectors';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    zoomContainer: {
      display: 'flex',
    },
    zoomItem: {
      width: '50%',
      height: 384,
      position: 'relative',
      overflow: 'hidden',
    },
    header: {
      padding: theme.spacing(8, 0, 2),
      '&:first-child': {
        paddingTop: 0,
      },
    },
    comparison: {
      width: '100%',
    },
    hidden: {
      visibility: 'hidden',
    },
    watermarkContainer: {
      position: 'relative',
      '&:after': {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        content: '""',
        background: 'url("/watermark.png")',
        backgroundSize: 1000,
        pointerEvents: 'none',
      },
    },
  })
);

const isLoaded = (url: string) => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.addEventListener('load', resolve);
    img.addEventListener('error', reject);
    img.src = url;
  });
};

type IProp = {};

export default function Comparison({}: IProp) {
  const comparisonList = useSelector(comparisonListSelector);
  const { loading } = useSelector(globalSelector);
  const classes = useStyles();
  const dispatch = useDispatch();

  const setLoading = (loading: boolean) => {
    dispatch(actions.global.setLoading(loading));
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      for (let i = 0; i < comparisonList.length; i++) {
        await isLoaded(comparisonList[i]);
      }
      setLoading(false);

      zoom(
        {},
        {
          scaleDifference: 0.1,
        }
      );
    })();
  }, []);

  return (
    <Main backButton>
      <div className={clx({ [classes.hidden]: loading })}>
        <Typography className={classes.header} variant="h5">
          Коллаж
        </Typography>
        <div className={clx(classes.zoomContainer, classes.watermarkContainer)}>
          {comparisonList.map((one: string) => (
            <div className={clx('zoom', classes.zoomItem)}>
              <img alt="" src={one} />
            </div>
          ))}
        </div>
        <Typography className={classes.header} variant="h5">
          Сравнение
        </Typography>
        <div className={classes.watermarkContainer}>
          <ImgComparisonSlider className={classes.comparison}>
            <img slot="first" width="100%" height={600} src={comparisonList[0]} />
            <img slot="second" width="100%" height={600} src={comparisonList[1]} />
          </ImgComparisonSlider>
        </div>
      </div>
    </Main>
  );
}

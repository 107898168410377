import { Record } from 'immutable';
import { Action, ActionType, IState } from './types';
import { getSessionStore } from '../../../utils';

const store = getSessionStore();

const defaultState: IState = Record({
  list: store?.compare?.list || [],
})();

export default function reducer(state = defaultState, action: Action | any) {
  const { type, payload } = action;

  switch (type) {
    case ActionType.AddToList: {
      return state.update('list', list => list.concat(payload.photo));
    }

    case ActionType.RemoveFromList: {
      return state.update('list', list => list.filter(x => x !== payload.photo));
    }

    case ActionType.SetList: {
      return state.set('list', payload.list);
    }

    default: {
      return state;
    }
  }
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import List, { Item } from '../List';
import Main from '../Main';
import * as actions from '../../../../redux/actions';
import { useApi } from '../../../../useApi';
import { IRoot } from '../../../../redux/reducers/route/types';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { makeBreadcrumbs } from '../../../../utils';
import Search from '../Search';

export default function Root() {
  const history = useHistory();
  const dispatch = useDispatch();
  const api = useApi();
  const [roots, setRoots] = useState<any>([]);

  const onItemClick = (root: IRoot) => {
    history.push(`/photos/${root.id}`);
    dispatch(actions.path.setRoot(root));
  };

  useEffect(() => {
    dispatch(actions.global.setLoading(true));
    dispatch(actions.path.setSession(null));
    dispatch(actions.path.setClient(null));
    dispatch(actions.path.setRoot(null));

    api.getRoots().then(roots => {
      setRoots(roots);
      dispatch(actions.global.setLoading(false));
    });
  }, []);

  return (
    <Main>
      <Breadcrumbs breadcrumbs={makeBreadcrumbs({})} />
      <div>
        <Search />
        <List>
          {roots.map((one: IRoot) => (
            <Item onClick={() => onItemClick(one)} label={one.label} key={one.id} />
          ))}
        </List>
      </div>
    </Main>
  );
}

import {IUser} from "../../../types";
import {Record} from 'immutable';

export interface IUserState {
  user?: IUser,
  loaded: boolean,
}

export interface IState extends Record<IUserState> {
}

export enum ActionType {
  SetLoaded = 'User:SetLoaded',
  SetUser = 'User:SetUser',
}

export interface ISetLoaded {
  type: ActionType.SetLoaded,
  payload: {
    loaded: boolean
  },
}

export interface ISetUser {
  type: ActionType.SetUser,
  payload: {
    user: IUser,
  },
}

export type Action = ISetLoaded | ISetUser;


import React from 'react';
import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type IProp = {
  children: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
  })
);

export default function Panel({ children }: IProp) {
  const classes = useStyles();

  return <div className={classes.paper}>{children}</div>;
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AddModal from '../AddModal';
import { RootState } from '../../../../redux/reducers';
import * as actions from '../../../../redux/actions';
import { useApi } from '../../../../useApi';
import { routeParams } from '../../types';

type IProp = {
  onClose: () => void;
  open?: boolean;
};

export default function AddSessionModal({ open, onClose }: IProp) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.getIn(['global'])).toJS();
  const { date, error } = useSelector((state: RootState) => state.getIn(['addClient'])).toJS();
  const api = useApi();
  const params: routeParams = useParams();
  const history = useHistory();

  const handleDateChange = (date: string | null) => {
    dispatch(actions.addClient.setDate(date));
  };

  const setLoading = (loading: boolean) => {
    dispatch(actions.global.setLoading(loading));
  };

  const setError = (error: string) => {
    dispatch(actions.addClient.setError(error));
  };

  const handleClose = () => {
    setError('');
    onClose();
  };

  const handleSubmit = async () => {
    if (!date || !date.isValid()) {
      setError('Неверный формат даты');
      return;
    }

    setLoading(true);
    const { rootID, clientID } = params;
    const formatDate = date.format('DD.MM.YYYY');

    const id = await api.addSession(rootID, clientID || '', formatDate);
    dispatch(actions.path.setSession({ id, date: formatDate, photos: [] }));

    handleDateChange(null);
    handleClose();

    history.push(`/photos/${rootID}/${clientID}/${id}`);
  };

  return (
    <AddModal
      disableName={true}
      open={open}
      onClose={handleClose}
      loading={loading}
      title="Добавление визита"
      subtitle="Введите дату нового визита."
      onDateChange={handleDateChange}
      onNameChange={() => {}}
      onSubmit={handleSubmit}
      name=""
      error={error}
      date={date}
    />
  );
}

import firebase from 'firebase';
import keys from './keys.json';
import { IUser } from './types';
import _ from 'lodash';

import { ALPHABET_ORDER } from './constants';

export interface IApi {
  app: firebase.app.App;

  getClientsVisits: (letter: string, name: string) => Promise<string[]>;
  getVisitPhotos: (letter: string, name?: string, visit?: string) => Promise<string[]>;
  getSnapshot: (path: string, img: string) => firebase.storage.UploadTask;
  getPrices: () => Promise<any[]>;
  userToken?: string;
  uid?: string;

  getUser(): Promise<IUser | null>;

  signIn(email: string, password: string): Promise<IUser | null>;

  signOut(): Promise<any>;

  getClientList(letterID: string): Promise<any>;

  addClient: (rootID: string, name: string, surname: string, patronymic?: string, prevSurname?: string) => any;
  addSession: (rootID: string, clientID: string, date: string) => any;
  getClients: (rootID: string) => Promise<any>;
  getRoots: () => Promise<any>;
  getRoot: (rootID: string) => Promise<any>;
  getClient: (rootID: string, clientID?: string) => Promise<any>;
  getSessions: (rootID: string, clientID: string) => Promise<any>;
  getSession: (rootID: string, clientID?: string, sessionID?: string) => Promise<any>;
  setPhotos: (rootID: string, clientID?: string, sessionID?: string, photos?: string[]) => Promise<any>;
  setVideos: (rootID: string, clientID?: string, sessionID?: string, videos?: string[]) => Promise<any>;

  uploadPhoto(path: string, file: File): Promise<any>;

  uploadVideo(path: string, file: File): Promise<any>;

  deletePhoto(rootID?: string, clientID?: string, sessionID?: string, path?: string): Promise<any>;

  deleteSession(rootID?: string, clientID?: string, sessionID?: string): Promise<any>;

  deleteClient(rootID?: string, clientID?: string): Promise<any>;

  search: (query?: string) => Promise<any>;
}

class Api implements IApi {
  app: firebase.app.App;
  db: firebase.firestore.Firestore;

  userToken?: string;
  uid?: string;

  constructor() {
    this.app = firebase.initializeApp(keys);
    this.db = firebase.firestore();
  }

  async getRoots() {
    return this.getRemoveRoots();
  }

  async search(q: string = '') {
    const query = _.startCase(q);
    if (!query) return '';
    const arr = query.split(' ');

    if (arr.length === 1) {
      let docs: any = [];

      for (let i = 0; i < arr.length; i++) {
        docs = docs.concat(await this.substringSearch('surname', arr[i]));
        docs = docs.concat(await this.substringSearch('name', arr[i]));
        docs = docs.concat(await this.substringSearch('patronymic', arr[i]));
      }

      return docs.map((d: any) => ({ ...d.data(), id: d?.id, rootID: d?.ref?.parent?.parent?.id }));
    } else if (arr.length === 2) {
      let docs: any = [];
      docs = docs.concat(await this.searchBySurnameAndName(arr[0], arr[1]));
      docs = docs.concat(await this.searchByNameAndPatronymic(arr[0], arr[1]));

      return docs.map((d: any) => ({ ...d.data(), id: d?.id, rootID: d?.ref?.parent?.parent?.id }));
    } else if (arr.length === 3) {
      const docs = await this.searchByFullName(arr[0], arr[1], arr[2]);

      return docs.map((d: any) => ({ ...d.data(), id: d?.id, rootID: d?.ref?.parent?.parent?.id }));
    }
  }

  async substringSearch(field: string, query: string): Promise<any> {
    const { docs } = await this.db
      .collectionGroup('clients')
      .where(field, '>=', query)
      .where(field, '<=', query + '\uf8ff')
      .get();

    return docs;
  }

  async searchBySurnameAndName(surname: string, name: string): Promise<any> {
    const { docs } = await this.db
      .collectionGroup('clients')
      .where('surname', '==', surname)
      .where('name', '>=', name)
      .where('name', '<=', name + '\uf8ff')
      .get();

    return docs;
  }

  async searchByNameAndPatronymic(name: string, patronymic: string): Promise<any> {
    const { docs } = await this.db
      .collectionGroup('clients')
      .where('name', '==', name)
      .where('patronymic', '>=', patronymic)
      .where('patronymic', '<=', patronymic + '\uf8ff')
      .get();

    return docs;
  }

  async searchByFullName(surname: string, name: string, patronymic: string): Promise<any> {
    const { docs } = await this.db
      .collectionGroup('clients')
      .where('surname', '==', surname)
      .where('name', '==', name)
      .where('patronymic', '>=', patronymic)
      .where('patronymic', '<=', patronymic + '\uf8ff')
      .get();

    return docs;
  }

  async getRemoveRoots() {
    const photobase = await this.db.collection('photobase').get();

    return photobase.docs
      .map(doc => ({ ...doc.data(), id: doc.id, clients: [] }))
      .sort((a: any, b: any) => ALPHABET_ORDER.indexOf(a.label) - ALPHABET_ORDER.indexOf(b.label));
  }

  async getRoot(rootID: string) {
    const root = await this.db.collection('photobase').doc(rootID).get();

    return { ...root.data(), id: rootID };
  }

  async getClients(rootID: string) {
    const clients = await this.db.collection('photobase').doc(rootID).collection('clients').get();

    return clients.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .sort((a: any, b: any) => (a.surname > b.surname ? 1 : -1));
  }

  async getClient(rootID: string, clientID: string = '') {
    const client = await this.db.collection('photobase').doc(rootID).collection('clients').doc(clientID).get();

    return { ...client.data(), id: clientID };
  }

  async getSessions(rootID: string, clientID: string) {
    const visits = await this.db
      .collection('photobase')
      .doc(rootID)
      .collection('clients')
      .doc(clientID)
      .collection('sessions')
      .get();

    return visits.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }

  async getSession(rootID: string, clientID: string = '', sessionID: string = '') {
    const session = await this.getSessionDoc(rootID, clientID, sessionID).get();

    return { ...session.data(), id: sessionID };
  }

  async setVideos(rootID: string, clientID: string = '', sessionID: string = '', videos: string[] = []) {
    const sessionDoc = this.getSessionDoc(rootID, clientID, sessionID);

    return await sessionDoc.update({
      videos,
    });
  }

  async setPhotos(rootID: string, clientID: string = '', sessionID: string = '', photos: string[] = []) {
    const sessionDoc = this.getSessionDoc(rootID, clientID, sessionID);

    return await sessionDoc.update({
      photos,
    });
  }

  async addSession(rootID: string, clientID: string, date: string) {
    const client = await this.db.collection('photobase').doc(rootID).collection('clients').doc(clientID);
    const visits = client.collection('sessions');
    const doc = visits.doc();

    await doc.set({
      date,
      photos: [],
      videos: [],
    });

    return doc.id;
  }

  async addClient(rootID: string, name: string, surname: string, patronymic?: string, prevSurname?: string) {
    const doc = await this.db.collection('photobase').doc(rootID).collection('clients').add({
      name,
      surname,
      patronymic,
      prevSurname,
    });

    return doc.id;
  }

  async getClientList(letterID: string) {
    const letter = await this.db.collection('photobase').doc(letterID);
    const letterData = await letter.get();
    const clients = await letter.collection('clients').get();
    const list = clients.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    return {
      letter: {
        name: letterData.data()?.name,
        id: letterID,
      },
      list,
    };
  }

  async signIn(email: string, password: string): Promise<IUser | null> {
    try {
      await this.app.auth().signInWithEmailAndPassword(email, password);
      return await this.getUser();
    } catch (e) {
      throw new Error(e);
    }
  }

  async signOut() {
    try {
      await this.app.auth().signOut();
      window.location.href = '/';
      sessionStorage.removeItem('Store');
    } catch (e) {
      throw new Error(e);
    }
  }

  getUser(): Promise<IUser | null> {
    return new Promise(resolve => {
      firebase.auth().onAuthStateChanged(user => {
        if (!user) return resolve(null);

        user.getIdTokenResult().then(({ claims, token }) => {
          resolve({
            token: (this.userToken = token),
            email: user.email || '',
            name: user.displayName || claims.name || user.email || '',
            id: (this.uid = claims.user_id),
            role: claims.role,
          });
        });
      });
    });
  }

  getImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        resolve(image);
      };
      image.onerror = (error: any) => {
        reject(error);
      };
      image.src = src;
    });
  }

  getSnapshot(path: string, img: string) {
    const storageRef = this.getStorageRef();
    return storageRef.child(path).putString(img, 'base64');
  }

  async uploadVideo(path: string, file: File): Promise<any> {
    try {
      const storageRef = this.getStorageRef();
      const snapshot = await storageRef.child(path).put(file);

      return await snapshot.ref.getDownloadURL();
    } catch (e) {
      return e;
    }
  }

  async uploadPhoto(path: string, file: File): Promise<any> {
    try {
      const headers = new Headers();
      headers.append('Content-Type', 'image/jpeg');

      const response = await fetch('https://us-central1-nudebar-admin.cloudfunctions.net/api/upload', {
        method: 'POST',
        headers,
        body: file,
      });
      const result = await response.arrayBuffer();

      const storageRef = this.getStorageRef();
      const snapshot = await storageRef.child(path).put(result);

      return await snapshot.ref.getDownloadURL();
    } catch (e) {
      return e;
    }
  }

  getClientsDoc(rootID: string = '', clientID: string = '') {
    return this.getRootDoc(rootID).collection('clients').doc(clientID);
  }

  getRootDoc(rootID: string = '') {
    return this.db.collection('photobase').doc(rootID);
  }

  getSessionDoc(rootID: string = '', clientID: string = '', sessionID: string = '') {
    return this.getClientsDoc(rootID, clientID).collection('sessions').doc(sessionID);
  }

  async deletePhoto(rootID: string, clientID: string, sessionID: string, photoID: string): Promise<any> {
    const storageRef = this.getStorageRef();
    const sessionDoc = this.getSessionDoc(rootID, clientID, sessionID);
    const session = await sessionDoc.get();

    const photos = session.data()?.photos;
    const photo = photos.find((x: any) => x.id === photoID);
    const filteredPhotos = photos.filter((x: any) => x.id !== photoID);

    await storageRef.child(photo.path).delete();

    return await sessionDoc.update({
      photos: filteredPhotos,
    });
  }

  async deleteSession(rootID: string, clientID: string, sessionID: string): Promise<any> {
    const storageRef = this.getStorageRef();
    const sessionDoc = this.getSessionDoc(rootID, clientID, sessionID);
    const session = await sessionDoc.get();
    const photos = session.data()?.photos || [];

    for (let i = 0; i < photos.length; i++) {
      await storageRef.child(photos[i].path).delete();
    }

    return await sessionDoc.delete();
  }

  async deleteClient(rootID: string, clientID: string): Promise<any> {
    const clientsDoc = this.getClientsDoc(rootID, clientID);
    const sessions = await clientsDoc.collection('sessions').get();

    for (let i = 0; i < sessions.docs.length; i++) {
      const { id } = sessions.docs[i];
      await this.deleteSession(rootID, clientID, id);
    }

    return await clientsDoc.delete();
  }

  getStorageRef() {
    return this.app.storage().ref('/clients');
  }

  async getClientsVisits(letter: string, name: string) {
    const storageRef = this.getStorageRef();
    const letterRef = storageRef.child(letter);
    const clientRef = letterRef.child(name);
    const list = await clientRef.listAll();
    return list.prefixes.map(p => p.name);
  }

  async getVisitPhotos(letter: string, name: string = '', visit: string = ''): Promise<string[]> {
    const storageRef = this.getStorageRef();
    const visitRef = storageRef.child(letter).child(name).child(visit);
    const list = await visitRef.listAll();

    type item = { metadata: any; src: string };
    const items: item[] = [];

    for (let i = 0; i < list.items.length; i++) {
      const metadata = await list.items[i].getMetadata();
      const src = await list.items[i].getDownloadURL();
      items.push({ metadata, src });
    }

    items.sort((a: item, b: item) => {
      return new Date(a.metadata.updated).getTime() > new Date(b.metadata.updated).getTime() ? 1 : -1;
    });

    return items.map(item => item.src);
  }

  async getPrices(): Promise<any[]> {
    const shapshot = await this.db.collection('price').get();
    return shapshot.docs.map(x => ({ ...x.data(), id: x.id }));
  }
}

export default Api;

import {ActionType, ISetLoaded, ISetUser} from "../../reducers/user/types";
import {IUser} from "../../../types";

export function setLoaded(loaded: boolean): ISetLoaded {
  return {
    type: ActionType.SetLoaded,
    payload: {
      loaded,
    }
  }
}

export function setUser(user: IUser): ISetUser {
  return {
    type: ActionType.SetUser,
    payload: {
      user
    },
  }
}
import { Record } from 'immutable';
import { Action, ActionType, IClients, IState } from './types';
import { getSessionStore } from '../../../utils';

const store = getSessionStore();
const alphabet = store?.route.alphabet || [];
const clients: IClients = [];

const defaultState: IState = Record({
  alphabet,
  clients,
})();

export default function reducer(state = defaultState, action: Action | any) {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SetClients: {
      return state.set('clients', payload.clients);
    }

    case ActionType.AddClient: {
      return state.update(payload.letter, letter => letter.concat(payload.client));
    }

    case ActionType.SetAlphabet: {
      return state.set('alphabet', payload.alphabet);
    }

    default: {
      return state;
    }
  }
}

import { ActionType, ISetLoading, ISetDate, ISetName, ISetVisible, ISetError } from '../../reducers/addClient/types';

export function setLoading(loading: boolean): ISetLoading {
  return {
    type: ActionType.SetLoading,
    payload: {
      loading,
    },
  };
}

export function setDate(date: any): ISetDate {
  return {
    type: ActionType.SetDate,
    payload: {
      date,
    },
  };
}

export function setName(name: string): ISetName {
  return {
    type: ActionType.SetName,
    payload: {
      name,
    },
  };
}

export function setError(error: string): ISetError {
  return {
    type: ActionType.SetError,
    payload: {
      error,
    },
  };
}

export function setVisible(visible: boolean): ISetVisible {
  return {
    type: ActionType.SetVisible,
    payload: {
      visible,
    },
  };
}

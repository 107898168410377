import { createStore, applyMiddleware, compose, Middleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducer, { RootState } from '../reducers';

const saver: Middleware<{}, RootState> = storeApi => next => action => {
  next(action);
  const state = storeApi.getState();
  sessionStorage.setItem('Store', JSON.stringify(state.toJS()));
};

const middlewares = [saver];

middlewares.push(thunk);

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    stateTransformer: (state: RootState) => state.toJS(),
  });

  middlewares.push(logger);
}

const store = compose(applyMiddleware(...middlewares))(createStore)(reducer);

export default store;

import React from 'react';
import { Breadcrumbs as MaterialBreadcrumbs, Button } from '@material-ui/core/';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { IBreadCrumb } from '../../apps/PhotoBase/types';

type IProp = {
  breadcrumbs: IBreadCrumb[];
};

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      minWidth: 'auto',
      letterSpacing: '1.2px',
    },
  })
);

export default function Breadcrumbs({ breadcrumbs }: IProp) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <MaterialBreadcrumbs>
      {breadcrumbs.map(breadcrumb => (
        <Button
          className={classes.button}
          variant="text"
          color="default"
          key={breadcrumb.href}
          onClick={() => history.push(breadcrumb.href)}
        >
          {breadcrumb.label}
        </Button>
      ))}
    </MaterialBreadcrumbs>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CreateNewFolderIcon from '@material-ui/icons/Backup';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CompareIcon from '@material-ui/icons/Compare';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import clx from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Header from '../Header';
import Panel from '../Panel';
import { useApi } from '../../../../useApi';
import { comparisonListSelector, globalSelector, userSelector } from '../../../../redux/selectors';
import { hasEditPermissions } from '../../../../roles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      minHeight: '100vh',
    },
    drawer: {
      background: '#051e34 0 50% no-repeat url(https://www.gstatic.com/mobilesdk/190424_mobilesdk/nav_nachos@2x.png)',
      backgroundSize: 'cover',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      padding: 0,
    },
    grid: {
      minHeight: `calc(100vh - 64px)`,
    },
    colorWhite: {
      color: 'white',
    },
    button: {
      transition: 'background .25s ease-in',
      background: 'rgba(255, 255, 255, 0)',
      '&:hover': {
        background: 'rgba(255, 255, 255, .1)',
      },
    },
    loading: {
      pointerEvents: 'none',
    },
  })
);

type IProp = {
  children: any;
  comparisonButton?: boolean;
  backButton?: boolean;
  onFilesUpload?: (files: FileList) => void;
  showAddClient?: () => void;
  onAddSession?: () => void;
  onDeleteSession?: () => void;
  onDeleteClient?: () => void;
};

export default function Main({
  children,
  onFilesUpload,
  showAddClient,
  onAddSession,
  comparisonButton,
  backButton,
  onDeleteSession,
  onDeleteClient,
}: IProp) {
  const classes = useStyles();
  const history = useHistory();
  const comparisonList = useSelector(comparisonListSelector);
  const { loading } = useSelector(globalSelector);
  const { user } = useSelector(userSelector);
  const api = useApi();

  const hasPermission = hasEditPermissions(user?.role);

  return (
    <Container disableGutters maxWidth={false} className={clx(classes.container, { [classes.loading]: loading })}>
      <Header />
      <Grid container className={classes.grid} alignItems="stretch">
        <Grid md={2}>
          <Box className={classes.drawer}>
            <Divider />
            <List>
              {hasPermission && showAddClient && (
                <ListItem className={classes.button} button component="label" onClick={() => showAddClient()}>
                  <ListItemIcon>
                    <PersonAddIcon className={classes.colorWhite} />
                  </ListItemIcon>
                  <ListItemText className={classes.colorWhite} primary="Добавить клиента" />
                </ListItem>
              )}

              {hasPermission && !!onFilesUpload && (
                <ListItem className={classes.button} button key="Загрузить фото" component="label">
                  <input
                    type="file"
                    hidden
                    multiple
                    accept="image/jpeg,video/*"
                    onChange={event => {
                      event.target.files && onFilesUpload(event.target.files);
                    }}
                  />
                  <ListItemIcon>
                    <CreateNewFolderIcon className={classes.colorWhite} />
                  </ListItemIcon>
                  <ListItemText className={classes.colorWhite} primary="Загрузить фото" />
                </ListItem>
              )}

              {hasPermission && !!onAddSession && (
                <ListItem className={classes.button} button component="label" onClick={() => onAddSession()}>
                  <ListItemIcon>
                    <EventAvailableIcon className={classes.colorWhite} />
                  </ListItemIcon>
                  <ListItemText className={classes.colorWhite} primary="Добавить визит" />
                </ListItem>
              )}

              {hasPermission && !!onDeleteClient && (
                <ListItem className={classes.button} button component="label" onClick={() => onDeleteClient()}>
                  <ListItemIcon>
                    <RemoveCircleIcon className={classes.colorWhite} />
                  </ListItemIcon>
                  <ListItemText className={classes.colorWhite} primary="Удалить клиента" />
                </ListItem>
              )}

              {hasPermission && !!onDeleteSession && (
                <ListItem className={classes.button} button component="label" onClick={() => onDeleteSession()}>
                  <ListItemIcon>
                    <RemoveCircleIcon className={classes.colorWhite} />
                  </ListItemIcon>
                  <ListItemText className={classes.colorWhite} primary="Удалить визит" />
                </ListItem>
              )}
            </List>
            {comparisonList.length === 2 && comparisonButton && (
              <>
                <Divider />
                <ListItem
                  className={classes.button}
                  button
                  component="label"
                  onClick={() => {
                    history.push('/photos/comparison');
                  }}
                >
                  <ListItemIcon>
                    <CompareIcon className={classes.colorWhite} />
                  </ListItemIcon>
                  <ListItemText className={classes.colorWhite} primary="Сравнить Фото" />
                </ListItem>
              </>
            )}
            {backButton && (
              <>
                <Divider />
                <ListItem
                  className={classes.button}
                  button
                  component="label"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ListItemIcon>
                    <KeyboardBackspaceIcon className={classes.colorWhite} />
                  </ListItemIcon>
                  <ListItemText className={classes.colorWhite} primary="Назад" />
                </ListItem>
              </>
            )}
            <div style={{ marginTop: 'auto' }}>
              <Divider />
              <ListItem
                className={classes.button}
                button
                component="label"
                onClick={() => {
                  api.signOut();
                }}
              >
                <ListItemIcon>
                  <LogOutIcon className={classes.colorWhite} />
                </ListItemIcon>
                <ListItemText className={classes.colorWhite} primary="Выйти" />
              </ListItem>
            </div>
          </Box>
        </Grid>
        <Grid md={10}>
          <main className={classes.main}>
            <Panel>{children}</Panel>
          </main>
        </Grid>
      </Grid>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CreateNewFolderIcon from '@material-ui/icons/Backup';
import { routeParams } from '../../types';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { makeBreadcrumbs } from '../../../../utils';
import Main from '../Main';
import { useApi } from '../../../../useApi';
import { Button, Grid, Typography } from '@material-ui/core';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Photo from '../Photo';
import * as actions from '../../../../redux/actions';
import { IClient, IRoot, ISession } from '../../../../redux/reducers/route/types';
import { globalSelector, pathSelector, userSelector } from '../../../../redux/selectors';
import { hasAdminPermissions, hasEditPermissions } from '../../../../roles';
import ConfirmModal from '../../../../components/ConfirmModal';

type IProp = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
    colorWhite: {
      color: 'white',
    },
    mediaList: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: theme.spacing(-1),
    },
    mediaListItem: {
      margin: theme.spacing(1),
      cursor: 'pointer',
      position: 'relative',
      backgroundColor: theme.palette.grey[200],
    },
    uploadBlock: {
      paddingTop: theme.spacing(4),
    },
    videoBlock: {
      padding: theme.spacing(8, 0, 0, 0),
    },
    videoBlockTitle: {
      padding: theme.spacing(0, 0, 2, 0),
    },
  })
);

export default function Session({}: IProp) {
  const params: routeParams = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector(globalSelector);
  const { root, client, session } = useSelector(pathSelector);
  const { user } = useSelector(userSelector);
  const api = useApi();
  const [openGallery, toggleGallery] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [photoToDelete, setPhotoToDelete] = useState('');
  const [isDeleteSessionVisible, setDeleteSessionVisible] = useState(false);
  const { rootID, clientID, sessionID } = params;

  const setLoading = (loading: boolean) => {
    dispatch(actions.global.setLoading(loading));
  };

  const showGallery = (index: number) => {
    setPhotoIndex(index);
    toggleGallery(true);
  };

  const saveClient = (client: IClient) => {
    dispatch(actions.path.setClient(client));
  };

  const saveRoot = (root: IRoot) => {
    dispatch(actions.path.setRoot(root));
  };

  const saveSession = (session: ISession) => {
    dispatch(actions.path.setSession(session));
  };

  const onPhotoDelete = async (photoID: string) => {
    setLoading(true);
    await api.deletePhoto(rootID, clientID, sessionID, photoID);
    saveSession(await api.getSession(rootID, clientID, sessionID));
    setPhotoToDelete('');
    setLoading(false);
  };

  const onSessionDelete = async () => {
    setLoading(true);
    await api.deleteSession(rootID, clientID, sessionID);
    const client = await api.getClient(params.rootID, params.clientID);
    saveClient(client);
    setDeleteSessionVisible(false);
    setLoading(false);
    history.push(`/photos/${rootID}/${clientID}`);
  };

  const uploadPhotos = async (files: File[]) => {
    const photos = [];

    for (let i = 0; i < files.length; i++) {
      const id = v4();
      const path = `${rootID}/${clientID}/${sessionID}/${id}.webp`;
      const file = files[i];
      const url = await api.uploadPhoto(path, file);
      photos.push({
        id,
        path,
        url,
      });
    }

    await api.setPhotos(root.id, client.id, session.id, [...session.photos, ...photos]);
  };

  const uploadVideos = async (files: File[]) => {
    const videos = [];

    for (let i = 0; i < files.length; i++) {
      const id = v4();
      const file = files[i];
      const ext = file.name.split('.').pop();
      const path = `${rootID}/${clientID}/${sessionID}/${id}.${ext}`;
      const url = await api.uploadVideo(path, file);
      videos.push({
        id,
        path,
        url,
      });
    }

    await api.setVideos(root.id, client.id, session.id, [...(session.videos || []), ...videos]);
  };

  const onFilesUpload = async (files: FileList) => {
    setLoading(true);
    const photoFiles = [].filter.call(files, (f: File) => f.type.indexOf('image') > -1);
    const videoFiles = [].filter.call(files, (f: File) => f.type.indexOf('video') > -1);

    await uploadPhotos(photoFiles);
    await uploadVideos(videoFiles);

    saveSession(await api.getSession(root.id, client.id, session.id));
    setLoading(false);
  };

  const hasPermission = hasEditPermissions(user?.role);
  const hasAdminPermission = hasAdminPermissions(user?.role);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (!root) {
        const root = await api.getRoot(params.rootID);
        saveRoot(root);
      }

      if (!client) {
        const client = await api.getClient(params.rootID, params.clientID);
        saveClient(client);
      }

      if (!session) {
        const session = await api.getSession(params.rootID, params.clientID, params.sessionID);
        saveSession(session);
      }

      setLoading(false);
    })();

    document.addEventListener('contextmenu', (e: any) => {
      e.preventDefault();
    });
  }, []);

  let { photos, videos } = session || { photos: [], videos: [] };

  if (!videos) videos = [];

  return (
    <Main
      onDeleteSession={() => setDeleteSessionVisible(true)}
      onFilesUpload={onFilesUpload}
      comparisonButton={!loading}
    >
      {!!root && !!client && !!session && (
        <>
          <Breadcrumbs
            breadcrumbs={makeBreadcrumbs({
              root,
              client,
              session,
            })}
          />
          <div className={classes.content}>
            {photos.length > 0 && (
              <div className={classes.mediaList}>
                {photos.map((photo: { url: string; id: string }, index: number) => (
                  <div className={classes.mediaListItem} key={photo.id} onClick={() => showGallery(index)}>
                    <Photo
                      hasAdminPermission={hasAdminPermission}
                      hasPermission={hasPermission}
                      photo={photo.url}
                      onDelete={() => setPhotoToDelete(photo.id)}
                    />
                  </div>
                ))}
              </div>
            )}
            {videos.length > 0 && (
              <div className={classes.videoBlock}>
                <Typography className={classes.videoBlockTitle} variant="h5">
                  Видео:
                </Typography>
                <Grid container spacing={2}>
                  {videos.map((video: any) => (
                    <Grid item key={video.id}>
                      <video controls src={video.url} width="300" height="200" />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
            {!loading && photos.length === 0 && videos.length === 0 && (
              <div className={classes.uploadBlock}>
                {hasPermission ? (
                  <Button
                    component="label"
                    color="primary"
                    variant="outlined"
                    size="large"
                    startIcon={<CreateNewFolderIcon />}
                  >
                    <input
                      type="file"
                      hidden
                      multiple
                      accept="image/jpeg,video/*"
                      onChange={event => {
                        event.target.files && onFilesUpload(event.target.files);
                      }}
                    />
                    Загрузить фото
                  </Button>
                ) : (
                  <Typography variant="h5">
                    Данный визит не содержит фото. Попросите администратор загрузить фото для этого визита
                  </Typography>
                )}
              </div>
            )}
          </div>
          {openGallery && (
            <Lightbox
              mainSrc={photos[photoIndex].url}
              nextSrc={photos[(photoIndex + 1) % photos.length].url}
              prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].url}
              onCloseRequest={() => toggleGallery(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
            />
          )}
        </>
      )}
      {!!photoToDelete && (
        <ConfirmModal
          title="Вы действительно хотите удалить фото?"
          loading={loading}
          onConfirm={() => onPhotoDelete(photoToDelete)}
          onClose={() => setPhotoToDelete('')}
        />
      )}
      {isDeleteSessionVisible && (
        <ConfirmModal
          title="Вы действительно хотите удалить визит?"
          loading={loading}
          onConfirm={onSessionDelete}
          onClose={() => setDeleteSessionVisible(false)}
        />
      )}
    </Main>
  );
}

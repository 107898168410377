import { IUser } from '../../../types';
import { Record } from 'immutable';

export interface IUserState {
  list: string[];
}

export interface IState extends Record<IUserState> {}

export enum ActionType {
  AddToList = 'Compare:AddToList',
  RemoveFromList = 'Compare:RemoveFromList',
  SetList = 'Compare:SetList',
}

export interface IAddToList {
  type: ActionType.AddToList;
  payload: {
    photo: string;
  };
}

export interface IRemoveFromList {
  type: ActionType.RemoveFromList;
  payload: {
    photo: string;
  };
}

export interface ISetList {
  type: ActionType.SetList;
  payload: {
    list: string[];
  };
}

export type Action = IAddToList | IRemoveFromList | ISetList;

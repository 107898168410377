import {Record, fromJS} from 'immutable';
import {Action, ActionType, IState} from './types';

const defaultState: IState = Record({
  user: undefined,
  loaded: false,
})();

export default function reducer(state = defaultState, action: Action | any) {
  const {type, payload} = action;

  switch (type) {
    case ActionType.SetLoaded: {
      return state.set('loaded', payload.loaded);
    }

    case ActionType.SetUser: {
      return state.set('user', fromJS(payload.user));
    }

    default: {
      return state;
    }
  }
}
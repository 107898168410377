import {Record} from 'immutable';

export interface IGlobalState {
  loading: boolean,
}

export interface IState extends Record<IGlobalState> {
}

export enum ActionType {
  SetLoading = 'Global:SetLoading',
}

export interface ISetLoading {
  type: ActionType.SetLoading,
  payload: {
    loading: boolean,
  },
}

export type Action = ISetLoading;


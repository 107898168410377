import { Record } from 'immutable';

export type IRouteState = Route;

export type IRoot = {
  label: string;
  id: string;
};

export type IClients = IClient[];

export type Route = {
  alphabet: IRoot[];
  clients: IClients;
};

export type IClient = {
  name: string;
  surname: string;
  patronymic?: string;
  prevSurname?: string;
  id: string;
};

export type ISession = {
  date: string;
  photos: string[];
  id: string;
};

export interface IState extends Record<IRouteState> {}

export enum ActionType {
  SetAlphabet = 'Route:SetAlphabet',
  SetClients = 'Route:SetClients',
  AddClient = 'Route:AddClient',
}

export interface ISetAlphabet {
  type: ActionType.SetAlphabet;
  payload: {
    alphabet: IRoot[];
  };
}

export interface ISetClients {
  type: ActionType.SetClients;
  payload: {
    clients: IClients;
  };
}

export interface IAddClient {
  type: ActionType.AddClient;
  payload: {
    letter: string;
    client: IClient;
  };
}

export type Action = ISetClients | IAddClient | ISetAlphabet;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: 110,
      height: 110,
      color: theme.palette.primary.main,
      transition: 'color .25s ease-in-out',
    },
    folder: {
      background: 'none',
      border: 'none',
      position: 'relative',
      textAlign: 'center',
      margin: '0 auto',
      display: 'block',
      cursor: 'pointer',
      outline: 'none',
      '&:hover $icon': {
        color: theme.palette.primary.dark,
      },
    },
    letter: {
      fontSize: 12,
    },
    container: {
      marginTop: theme.spacing(4),
    },
  })
);

type IFolderType = 'default' | 'special' | 'shared' | 'calendar';

export function Item({ label, onClick, type = 'default' }: { label: string; onClick: () => void; type?: IFolderType }) {
  const classes = useStyles();

  let Icon;
  switch (type) {
    case 'default':
      Icon = FolderIcon;
      break;
    case 'calendar':
      Icon = InsertInvitationIcon;
      break;
    case 'shared':
      Icon = FolderSharedIcon;
      break;
    case 'special':
      Icon = FolderSpecialIcon;
      break;
  }

  return (
    <Grid item xs={2}>
      <button className={classes.folder} onClick={() => onClick()}>
        <Icon className={classes.icon} />
        <Typography variant="subtitle2" className={classes.letter}>
          {label}
        </Typography>
      </button>
    </Grid>
  );
}

export default function List({ children }: { children: any }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={6}>
        {children}
      </Grid>
    </div>
  );
}

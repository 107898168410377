import { Record } from 'immutable';
import { IClient, IRoot, ISession } from '../route/types';

export interface IPathState {
  root?: IRoot;
  client?: IClient;
  session?: ISession;
}

export interface IState extends Record<IPathState> {}

export enum ActionType {
  SetRoot = 'Path:SetRoot',
  SetClient = 'Path:SetClient',
  SetSession = 'Path:SetSession',
}

export interface ISetRoot {
  type: ActionType.SetRoot;
  payload: {
    root: IRoot | null;
  };
}

export interface ISetClient {
  type: ActionType.SetClient;
  payload: {
    client: IClient | null;
  };
}

export interface ISetSession {
  type: ActionType.SetSession;
  payload: {
    session: ISession | null;
  };
}

export type Action = ISetRoot | ISession | ISetSession;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardActions, CardMedia, CircularProgress, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { addToList, removeFromList, setList } from '../../../../redux/actions/compare';
import { RootState } from '../../../../redux/reducers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mediaListItemLoader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginLeft: -20,
      marginTop: -20,
    },
    add: {
      color: theme.palette.success.main,
    },
    remove: {
      color: theme.palette.warning.main,
    },
    cardMedia: {
      width: 300,
      height: 250,
      position: 'relative',
      '&:after': {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        content: '""',
        background: 'url("/watermark.png")',
        backgroundSize: 1000,
      },
    },
    deleteIcon: {
      marginLeft: 'auto !important',
      color: theme.palette.error.dark,
    },
    downloadLink: {
      marginLeft: 'auto !important',
    },
    downloadIcon: {
      color: theme.palette.success.dark,
    },
  })
);

type IProp = {
  photo: string;
  onDelete: () => void;
  hasPermission: boolean;
  hasAdminPermission: boolean;
};

export default function Photo({ photo, onDelete, hasPermission, hasAdminPermission }: IProp) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const comparisonList = useSelector((state: RootState) => state.getIn(['compare', 'list']));

  const addToCompare = (photo: string) => {
    if (comparisonList.length > 1) {
      dispatch(setList([comparisonList[1], photo]));
      return;
    }

    dispatch(addToList(photo));
  };

  const removeToCompare = (photo: string) => {
    dispatch(removeFromList(photo));
  };

  const include = comparisonList.includes(photo);

  return (
    <Card>
      <CircularProgress className={classes.mediaListItemLoader} />
      <CardMedia image={photo} className={classes.cardMedia} />
      <CardActions>
        {include ? (
          <IconButton
            onClick={event => {
              event.stopPropagation();
              removeToCompare(photo);
            }}
          >
            <CancelIcon className={classes.remove} />
          </IconButton>
        ) : (
          <IconButton
            onClick={event => {
              event.stopPropagation();
              addToCompare(photo);
            }}
          >
            <AddIcon className={classes.add} />
          </IconButton>
        )}
        {hasAdminPermission && (
          <a
            href={photo}
            onClick={event => {
              event.stopPropagation();
            }}
            download
            className={classes.downloadLink}
          >
            <IconButton className={classes.downloadIcon}>
              <DownloadIcon />
            </IconButton>
          </a>
        )}
        {hasPermission && (
          <IconButton
            className={classes.deleteIcon}
            onClick={event => {
              event.stopPropagation();
              onDelete();
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
}

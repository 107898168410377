export enum IRole {
  doctor = 'doctor',
  administrator = 'administrator',
  director = 'director',
  admin = 'admin',
}

export function getRoleAccess(role: IRole) {
  switch (role) {
    case IRole.admin:
    case IRole.director:
      return 30;
    case IRole.administrator:
      return 20;
    case IRole.doctor:
      return 10;
  }
}

export function getRoleName(role: IRole) {
  switch (role) {
    case IRole.admin:
      return 'admin';
    case IRole.doctor:
      return 'специалист';
    case IRole.director:
      return 'директор';
    case IRole.administrator:
      return 'администратор';
  }
}

export function hasEditPermissions(role?: IRole) {
  if (!role) return false;
  return [IRole.director, IRole.admin, IRole.administrator].includes(role);
}

export function hasAdminPermissions(role?: IRole) {
  if (!role) return false;
  return [IRole.director, IRole.admin].includes(role);
}

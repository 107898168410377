import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useApi } from '../../../../useApi';
import { makeBreadcrumbs } from '../../../../utils';
import * as actions from '../../../../redux/actions';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Main from '../Main';
import { routeParams } from '../../types';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IClient, IRoot } from '../../../../redux/reducers/route/types';
import List, { Item } from '../List';
import { hasEditPermissions } from '../../../../roles';
import { globalSelector, pathSelector, userSelector } from '../../../../redux/selectors';
import AddClientModal from '../AddClientModal';
import { getClientName } from '../../../../utils/name';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noClientsBlock: {
      padding: theme.spacing(5, 0, 0, 0),
    },
  })
);

export default function Clients() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: routeParams = useParams();
  const classes = useStyles();
  const api = useApi();
  const { loading } = useSelector(globalSelector);
  const { root } = useSelector(pathSelector);
  const { user } = useSelector(userSelector);
  const [clients, setClients] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);

  const setLoading = (loading: boolean) => {
    dispatch(actions.global.setLoading(loading));
  };

  const addClient = () => {
    dispatch(actions.addClient.setVisible(true));
  };

  const saveClient = (client: IClient) => {
    dispatch(actions.path.setClient(client));
  };

  const saveRoot = (root: IRoot) => {
    dispatch(actions.path.setRoot(root));
  };

  const onItemClick = (client: IClient) => {
    history.push(`/photos/${params.rootID}/${client.id}`);
    saveClient(client);
  };

  const hasPermission = hasEditPermissions(user?.role);

  useEffect(() => {
    (async () => {
      setLoading(true);
      dispatch(actions.path.setSession(null));

      if (!root) {
        const root = await api.getRoot(params.rootID);
        saveRoot(root);
      }

      const clients = await api.getClients(params.rootID);
      setClients(clients);

      setLoading(false);
    })();
  }, []);

  return (
    <Main showAddClient={() => setShowModal(true)}>
      {root && <Breadcrumbs breadcrumbs={makeBreadcrumbs({ root })} />}
      {clients.length > 0 ? (
        <List>
          {clients.map((one: IClient) => (
            <Item type="shared" label={getClientName(one)} onClick={() => onItemClick(one)} key={one.id} />
          ))}
        </List>
      ) : (
        <div className={classes.noClientsBlock}>
          {hasPermission && !loading && (
            <Button
              startIcon={<PersonAddIcon />}
              size="large"
              variant="outlined"
              color="primary"
              onClick={() => setShowModal(true)}
            >
              Добавить клиента
            </Button>
          )}
        </div>
      )}
      {root && <AddClientModal onClose={() => setShowModal(false)} open={showModal} root={root} />}
    </Main>
  );
}
